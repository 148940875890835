<template>
  <div>
    <div>
      <b-table
        :items="table.items"
        :fields="$t('clan')=='en'?table.fields:tableAR.fields"
        :responsive="true"
        :current-page="currentPage"
        :per-page="perPage"
        stacked="md"
        show-empty
      >
      <template #empty>
          <div v-if="isBusy" class="text-center">
            <b-spinner
              variant="primary"
              label="Spinning"
              class="spinnerClass"
            ></b-spinner>
          </div>
          <p v-else class="text-center muted mb-0 mt-2" style="font-size: 13px">
            {{ $t("There are no records to show") }}
          </p>
        </template>

        <template #cell(actions)="row">
          <div class="">
            <button @click.prevent="viewQueue(row.item.id)">
              <i class="far fas fa-eye"></i>
              {{$t('view')}}
            </button>
            <button
              @click.prevent="showModal(row.item.id, row.item.tarrif_name)"
              class="ml-5"
            >
              <i class="fas fa-trash mr-2"></i>{{$t('Delete')}}
            </button>
          </div>
        </template>



    <template #cell(zones)='row'>
      

      
      <b-badge
            variant="warning"
            
           v-for="zone in JSON.parse(row.item.zones)"
           :key="zone.id"
            class="mr-1 mt-2"
          >
            Radius:{{zone.distance}} KM / price:{{zone.price}}  JOD
          </b-badge>
         
    </template>
       

        <template #row-details="row">
          <b-card>
            <ul>
              <li v-for="(value, key) in row.item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>

     <!-- <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="left"
        size="lg"
        class="my-0"
        @click.native="getData"
      ></b-pagination> -->

      <ul class="pagination" :style="$t('clan')=='ar'?'float:right':''" dir="ltr">
    <li class="page-item "><a class="page-link" >{{$t('Prev')}}</a></li>
    <li class="page-item"><a class="page-link" >{{$t('Next')}}</a></li>
  </ul>
    </div>

    <b-modal
      id="modal-1"
      modal-class="modal"
      ref="my-modal"
      :title=" tempName+ ' tarrif Deletion'"
      hide-footer
    >
      <p>
        Are you sure that you want to delete this tarrif?
      </p>

      <div class="buttons text-right">
        <b-button variant="white" class="mr-3" @click="hideModal"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="deleteTarrif">Delete</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "TarrifTable",
  async mounted() {
    this.isBusy = true
    await this.$store.dispatch("clientTarrif/loadAll").then((res) => {
      this.totalRows = res.data.total;
      this.lastPage = res.data.last_page
      this.isBusy = false
    });
  },

  computed: {
    ...mapGetters({ table: "clientTarrif/table" }),
    ...mapGetters({ tableAR: "clientTarrif/tableAR" }),
  },
  data: function () {
    return {
      isBusy:'',
      queue: {
        id: null,
      },
      tempId: null,
      tempName: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      firstPage:1,
      lastPage:'',
      
    };
  },
  methods: {
    /*next(){
      if(this.currentPage < this.lastPage){
        this.currentPage = this.currentPage + 1
      }
      
      this.getData()

    },*/
    /*prev(){
      if(this.currentPage > this.firstPage){
        this.currentPage = this.currentPage - 1
      }
      
      this.getData()

    },
    async getData() {
      await this.$store.dispatch("queues/loadPage", this.currentPage);
    },*/
    viewQueue(id) {
      this.$router.push({ name: "EditTarrifClients", params: { id: id } });
    },
    async deleteTarrif() {
      //this.tarrif.id = this.tempId;
      var tarrif = {id:this.tempId}
      await this.$store.dispatch("clientTarrif/delete", tarrif);
      await this.$store.dispatch("clientTarrif/loadAll").then((res) => {
        this.totalRows = res.data.total;
      });
      this.hideModal();
    },

    showModal(id, name) {
        //console.log(id)
      this.$refs["my-modal"].show();
      this.tempId = id;
      this.tempName = name;
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
  },
};
</script>
<style lang="css" scoped>
</style>